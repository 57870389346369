.MainMenu {
    .MainMenu__Item {
        display: flex;

        .MainMenu__Item__Link {
            display: flex;
            padding: 20px 36px;
            position: relative;
            width: 100%;
            align-items: center;
            text-decoration: none;
            border-radius: 24px;
            position: relative;

            & > svg {
                pointer-events: none;
            }

            &:hover,
            &:active {
                text-decoration: none;
            }

            &.MainMenu__Item__Link__Active {
                background: linear-gradient(118.77deg, rgba(255, 255, 255, 0.15) 20.62%, rgba(255, 255, 255, 0) 99.9%);

                .MainMenu__Item__Link__Text > span {
                    font-weight: 500;
                }

                & + .MainMenu {
                    display: block;
                }
            }

            & + .MainMenu {
                display: none;
            }
        }



        .MainMenu__Item {
            margin: 0;

            .MainMenu__Item__Link {
                padding: 20px 36px;
                padding-left: 78px;
                background: none;
                opacity: 0.6;

                &.MainMenu__Item__Link__Active {
                    opacity: 1;
                }
            }
        }
    }
}
