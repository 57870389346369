.snippet-source {
    position: relative;
    overflow-x: auto;

    .snippet-title {
        display: flex;

        .snippet-tag {
            text-transform: uppercase;
            font-size: 0.6rem;
            padding: 0.1rem 0.5rem;
            background-color: var(--default-text-color);
            color: #ff9970;
            font-weight: bold;
            border-radius: 5px 5px 0 0;
        }
    }

    .snippet-code {
        background-color: var(--default-text-color);
        padding: 1rem;
        overflow-y: auto;
        max-height: 500px;

        &:hover {
            & > button {
                opacity: 1;
            }
        }

        code {
            max-width: 100%;
            overflow-x: hidden;
            color: #ffffff;
            background: none;
        }

        button {
            opacity: 0;
            position: absolute;
            top: 27px;
            right: 27px;
            transition: opacity linear 0.3s;
        }
    }
}
