.radialGradients {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    opacity: 0.5;

    .radialGradient {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        min-width: 1903px;
        min-height: 1903px;

        &.radialGradient1 {
            transform: translate(-50%, -50%);
            background: #6e3ef7;
            background: radial-gradient(circle, rgba(110, 62, 247, 0.8) 0, rgba(110, 62, 247, 0) 72%);
        }

        &.radialGradient2 {
            left: 50%;
            background: rgba(220, 112, 238, 0.5);
            background: radial-gradient(circle, rgba(220, 112, 238, 0.5) 0, rgba(220, 112, 238, 0) 100%);
            filter: blur(700px);
        }

        &.radialGradient3 {
            transform: translate(-90%, 30%);
            background: #faff00;
            background: radial-gradient(circle, rgba(250, 255, 0, 0.5) 0, rgba(250, 255, 0, 0) 100%);
            filter: blur(450px);
        }

        &.radialGradient4 {
            transform: translate(-50%, 150%);
            background: #6e3ef7;
            background: radial-gradient(circle, rgba(110, 62, 247, 0.5) 0, rgba(110, 62, 247, 0) 100%);
            filter: blur(800px);
        }

        &.radialGradient5 {
            transform: translate(90%, 200%);
            background: #faff00;
            background: radial-gradient(circle, rgba(250, 255, 0, 0.5) 0, rgba(250, 255, 0, 0) 100%);
            filter: blur(700px);
        }

        &.radialGradient6 {
            transform: translate(-50%, 260%);
            background: #6654f1;
            background: radial-gradient(circle, rgba(102, 84, 241, 0.5) 0, rgba(102, 84, 241, 0) 100%);
            filter: blur(700px);
        }
    }
}
